import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';

interface Props {
  // Define your component's props here
  legends: any;
  setLegends: Function;
}

const useStyles = makeStyles((theme: Theme) => ({
  legend: {
    position: 'relative',
    left: '36%',
  },
  legendContainer: {
    display: 'flex',
    gap: `${theme.spacing(1.5)}px`,
    listStyle: 'none',
    padding: '0px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px`,
    borderRadius: `${theme.spacing(0.5)}px`,
    cursor: 'pointer',
  },
  legendSelected: {
    backgroundColor: `${theme.palette.background.secondary}`,
  },
  smallBox: {
    width: `${theme.spacing(1.5)}px`,
    height: `${theme.spacing(1.5)}px`,
    borderRadius: `${theme.spacing(0.5)}px`,
    marginRight: `${theme.spacing(1)}px`,
  },
  seperator: {
    // marginLeft: `${theme.spacing(1)}px`,
    color: `${theme.palette.primary.main}15`,
  },
}));

const Legend = ({ legends, setLegends }: Props) => {
  // Implement your component's logic here
  const classes = useStyles();

  const handleLegendClicked = (e: any, legendName: string) => {
    setLegends(
      legends.map((legend: any) =>
        legend.name === legendName
          ? { ...legend, selected: !legend.selected }
          : legend,
      ),
    );
  };

  return (
    <ul className={classes.legendContainer}>
      {legends.map((legend: any, index: number) => {
        return (
          <li
            onClick={e => handleLegendClicked(e, legend.name)}
            className={`${classes.legendItem} ${
              legend.selected ? classes.legendSelected : ''
            }`}
          >
            <span
              className={classes.smallBox}
              style={{
                background: legend.bg,
              }}
            ></span>
            <span>{legend.name}</span>
            {index !== legends.length - 1 && (
              <span className={classes.seperator}>|</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Legend;
