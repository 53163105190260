/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useContext,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { SidebarPage } from '@backstage/core-components';
import {
  Storybook_Url,
} from '../utils/constant';
import {
  configApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import NavigateOut from '../NavigationOutOfDevx/NavigateOut';
import { AuthContext } from '../../providers/AuthProvider';
import { useUserProfile } from '../utils/useGetUserProfile';
import { isAdmin } from '../utils/Utils';
import { userApiRef } from '../../apis/userApi';
import {
  notificationApiRef,
  NotificationApi,
} from '../../apis/notificationApi';
import AppSidebar from './Sidebar';
import { ChatBotContext } from '../../providers/ChatBotProvider';
import { FrontendConfigContext } from '../../providers/FrontendConfigProvider';
import { PromptContext } from '../admin/promptManagement/providers/PromptProvider';

function runChatBotScript(botId: string) {
  // @ts-ignore
  if (window && window.AvaamoChatBot) {
    // @ts-ignore
    // TODO, use variablize chatbot url
    const chatBox = new window.AvaamoChatBot({
      url: `https://chatbot.cbre.com/web_channels/${botId}`,
    });
    chatBox.load();
  }
}

const MSSessionStorageRedirect = 'MSAL_LOGGEDIN_REDIRECT';

export type ENV = 'internal' | 'dev' | 'uat' | 'prod';

interface Props {
  children: React.ReactNode;
}

export const Root = ({ children }: Props) => {
  return <RootComponents>{children}</RootComponents>
}

export const RootComponents: React.FC<Props> = ({ children }) => {
  const [userInfo, setuserInfo] = useState<any>();
  const [isDomainAdmin, setIsDomainAdmin] = useState(false);
  const [openStorybook, setOpenStorybook] = useState(false);
  const {
    profile,
    accessToken,
    profEmail,
    profileName
  } = useUserProfile();
  const configApi = useApi(configApiRef);

  const { setEnabled } = useContext(ChatBotContext);
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const [showBot, setShowBot] = useState(false);
  const redirectUrl = sessionStorage.getItem(MSSessionStorageRedirect);
  const userApi = useApi(userApiRef);
  const promptContext = useContext(PromptContext)

  const { frontendConfigData } = useContext(FrontendConfigContext);
  const [sdkConfig, setSdkConfig] = useState<any>();

  useEffect(() => {
    if (frontendConfigData && frontendConfigData?.splitio?.authorizationKey && profEmail) {
      console.log('im here')
      setSdkConfig({
        core: {
          authorizationKey: frontendConfigData?.splitio?.authorizationKey,
          key: profEmail,
          trafficType: 'user',
        },
      });
    }
  },[frontendConfigData, profEmail]);

  useEffect(() => {
    setShowBot(frontendConfigData?.chatBot?.show === 'true');
    setEnabled(frontendConfigData?.chatBot?.show === 'true');
    if (frontendConfigData?.survey?.surveyInstance) {
      // @ts-ignore
      window.sg_beacon('init', frontendConfigData?.survey?.surveyInstance);
    }
  }, [frontendConfigData, accessToken]);

  useEffect(() => {
    const chatbotTag = document.getElementById('avm-web-channel');
    if (!chatbotTag && showBot) {
      runChatBotScript(frontendConfigData?.chatBot?.botId || '');
    }
  }, [accessToken, showBot]);

  const childRoutes = React.isValidElement(children) ? children?.props?.children : [];
  const allFixedRoutes = [
    '/docs/external',
    '/software-templates/templates/:templateName',
    '/software-templates/tasks/:taskId',
    'admin/gamification-control/',
  ];

  childRoutes.forEach((ch: any) => {
    let p = ch?.props?.path;
    if (p) {
      allFixedRoutes.push(p);
    }
  });
  const apiBaseUrl = configApi.getString('backend.baseUrl');
  const appBaseUrl = configApi.getString('app.baseUrl');

  const applicationId = configApi.getString('app.datadogRum.applicationId');
  const clientToken = configApi.getString('app.datadogRum.clientToken');
  const env = configApi.getString('app.datadogRum.env') as 'internal' | 'dev' | 'uat' | 'prod';

  const userName = profileName?.split('@')[0];

  const handleClose = () => {
    setOpenStorybook(false);
  };
  const handleYes = () => {
    datadogRum.addAction('ClickStoryBook', {
      id: profEmail,
      name: profileName,
      email: profEmail,
    });
    window.open(Storybook_Url[env], '_blank');
    setOpenStorybook(false);
  };
  // handle event redirect
  useEffect(() => {
    if (accessToken && accessToken !== '') {
      localStorage.setItem('msToken', accessToken);
      if (redirectUrl) {
        if (window) {
          sessionStorage.removeItem(MSSessionStorageRedirect);
          window.location.replace(`${apiBaseUrl}${redirectUrl}`);
        }
      }
    }
  }, [accessToken, redirectUrl]);

  useEffect(() => {
    if (profile && profEmail && profileName && appBaseUrl) {
      datadogRum.init({
        applicationId: applicationId,
        clientToken: clientToken,
        site: 'datadoghq.com',
        service: 'devx',
        sampleRate: 100,
        allowedTracingOrigins: [appBaseUrl, /https:\/\/.*\devx.cbre\.com/],
        trackInteractions: true,
        env: env,
      });
      datadogRum.setUser({
        id: profEmail,
        name: profileName,
        email: profEmail,
      });
      datadogRum.startSessionReplayRecording();
    }
  }, [profile, appBaseUrl]);

  useEffect(() => {
    if (!userInfo && profEmail) {
      userApi
        .getUserInfoByEmail(profEmail?.toLowerCase())
        .then((res: any) => {
          setuserInfo(res.data);
        })
        .catch((err: any) => {
          notificationApi.sendNotification({
            message: `Error occurred - ${err?.message}`,
            disapperAfterMs: 2500,
            severity: 'error',
          });
        });
    }
  }, [userInfo, profile, profEmail]);

  useEffect(() => {
    if (userInfo && !isAdmin(userInfo) && !promptContext.isloading && !location.pathname.includes('/admin')) {
      if (!promptContext.loading) {
        promptContext.fetchData(isAdmin(userInfo), profEmail);
      } else {
        if (promptContext.data.domains && promptContext.data.domains.length > 0) {
          setIsDomainAdmin(true);
        } else {
          setIsDomainAdmin(false);
        }
      }

    }
  }, [userInfo, promptContext.data.domains]);

  const NaviagteProps = {
    handleYes: handleYes,
    handleClose: handleClose,
    open: openStorybook,
  };

  return (
    <SplitFactoryProvider config={sdkConfig}>
    <AuthContext.Provider
      value={{
        userInfo,
        accessToken,
        profile,
        apiBaseUrl,
        profEmail,
        profileName,
        allFixedRoutes,
        isAdmin: isAdmin(userInfo),
        isDomainAdmin: isDomainAdmin
      }}
    >
      <SidebarPage>
        <AppSidebar
          handleYes={handleYes}
          isAdminFlag={isAdmin(userInfo)}
          userName={userName}
          profile={profile}
        />
        {children}
        {openStorybook && <NavigateOut {...NaviagteProps} />}
      </SidebarPage>
    </AuthContext.Provider>
    </SplitFactoryProvider>
  );
};
