import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { commonStyles } from '../../../styles/common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),
    content: {
      background: theme.palette.prompt.chat.background,
    },
    flexCenter: {
      display: 'flex',
      verticalAlign: 'middle',
      alignItems: 'center',
      justifyContent: 'center',
    },
    heading: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '32px',
    },
    outlined: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '4px',
    },
    welcomeWidget: {
      marginTop: `${theme.spacing(3)}px`,
    },
    welcomeContent: {
      '& h6': {
        fontSize: '28px',
      },
      '& > p:nth-of-type(1)': {
        fontSize: '24px',
        fontWeight: 400,
      },
      '& > p': {
        fontSize: '14px',
      },
    },
    inputField: {
      padding: `${theme.spacing(1.3125)}px ${theme.spacing(1.75)}px`,
      marginRight: `90px`,
      position: 'fixed',
      bottom: 0,
      marginBottom: `${theme.spacing(2)}px`,
    },
    buttonSection: {
      display: 'flex',
      alignItems: 'center',
    },
    chatAvatar: {
      marginRight: `${theme.spacing(1)}px`,
      width: '32px',
      height: '32px',
    },
    chatResponse: {
      marginTop: `${theme.spacing(4)}px`,
    },
    chat: {
      marginTop: '-14px',
    },
    chatContent: {
      height: 'calc(100vh - 91px - 225px)',
      marginBottom: '107px',
      overflowY: 'scroll',
      marginLeft: '100px',
      marginRight: '100px',
    },
  }),
);
