import { DiffEditor, MonacoDiffEditor } from '@monaco-editor/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import * as monaco from 'monaco-editor';
import { useEffectOnce } from 'react-use';
import { Select, SelectItem } from '@backstage/core-components';
import {
  ClearValueButton,
  FileUploadButton,
  CopyToClipboardButton,
  PasteFromClipboardButton,
} from './Buttons';
import Input from '@material-ui/icons/Input';
import { Tool } from '@drodil/backstage-plugin-toolbox';
import { commonStyles } from '../../../../styles/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),

    diffEditor: {
      '& .monaco-editor-background, .margin-view-overlays, .monaco-editor': {
        background: theme.palette.background.paper,
      },
      '& .monaco-diff-editor': {
        padding: '0px 10px 0px 0px',
      },
      '& .editor': {
        borderRadius: '4px !important',
        boxShadow: 'none !important',
      },
      '& .modified': {
        marginLeft: '10px',
      },
      '& .overflow-guard': {
        border: 'solid 1px #bbb !important',
        borderRadius: '4px !important',
      },
      '& .active-line-number': {
        color: `${theme.palette.text.primary} !important`,
      },
      '& .mtk1': {
        color: theme.palette.text.primary,
      },
    },
    editorButtonGroup: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    toolsBar: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      padding: '0 !important',
    },
    menuTabs: {
      height: 'calc(100vh - 160px);',
      '& div[class*="MuiTabScrollButton-vertical"]': {
        height: '10px',
      },
    },
    tabDivider: {
      marginTop: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      color: theme.palette.primary.main,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    search: {
      margin: theme.spacing(2),
      marginBottom: theme.spacing(1),
      display: 'flex',
      '& input': {
        marginLeft: theme.spacing(2),
        width: '100%',
        flex: 1,
      },
    },
    previewPaper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    toolContainer: {
      padding: '1rem',
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      zIndex: 10000,
      backgroundColor: theme.palette.background.default,
    },
    select: {
      minWidth: '200px',
    },
    fileUpload: {
      marginBottom: '5px',
      width: '100%',
    },
    halfWidth: {
      width: '50%',
      '& div[class*="MuiButtonGroup"]': {
        display: 'block',
      },
    },
    supportedFormats: {
      marginBottom: '8px',
    },
  }),
);

export type MonacoLanguages = { name: string; extensions: string[] };

type SampleButtonProps = {
  sample: string[];
  setInput: (input: string[]) => void;
};

const options: monaco.editor.IDiffEditorConstructionOptions = {
  originalEditable: true,
  diffCodeLens: true,
  dragAndDrop: true,
  tabCompletion: 'on',
  renderSideBySide: true,
  wordWrap: 'on',
  renderLineHighlight: 'none',
  renderOverviewRuler: false,
  overviewRulerBorder: false,
  automaticLayout: true,
  scrollbar: {
    vertical: 'hidden',
  },
};

function getLanguage(allowedLanguages: MonacoLanguages[], extension: string) {
  return allowedLanguages.find(monacoLanguage =>
    monacoLanguage.extensions.includes(extension as string),
  )?.name;
}

function readFileAndSetText(
  file: File | undefined,
  setText: (value: ((prevState: string) => string) | string) => void,
  setLanguage: (value: ((prevState: string) => string) | string) => void,
  allowedLanguages: MonacoLanguages[],
) {
  if (!file) {
    return;
  }

  const reader = new FileReader();
  reader.onload = async e => {
    // @ts-ignore
    setText(e.target.result);
  };
  reader.readAsText(file);
  let newLanguage = 'plaintext';
  const extension = `.${file.name.split('.').pop()}`;
  if (allowedLanguages?.length) {
    newLanguage = getLanguage(allowedLanguages, extension) || newLanguage;
  }
  setLanguage(newLanguage);
}

export const SampleButton = (props: SampleButtonProps) => {
  const { sample, setInput } = props;
  return (
    <Tooltip arrow title="Input sample">
      <Button
        size="small"
        startIcon={<Input />}
        onClick={() => setInput(sample)}
      >
        Sample
      </Button>
    </Tooltip>
  );
};

const FileDiff = () => {
  const classes = useStyles();
  const [originalFile, setOriginalFile] = useState<File>();
  const [modifiedFile, setModifiedFile] = useState<File>();

  const [originalText, setOriginalText] = useState('');
  const [modifiedText, setModifiedText] = useState('');

  const [language, setLanguage] = useState('plaintext');
  const [allowedLanguages, setAllowedLanguages] = useState<MonacoLanguages[]>(
    [],
  );

  const exampleOriginalText = 'Devx toolbox\n\ncompare text';
  const exampleModifiedText = 'Devx toolbox\ndiff editor';
  const handleLanguageSelect = (selected: any) => {
    setLanguage(selected);
  };

  useEffect(() => {
    readFileAndSetText(
      modifiedFile,
      setModifiedText,
      setLanguage,
      allowedLanguages,
    );
  }, [modifiedFile, allowedLanguages]);

  useEffect(() => {
    readFileAndSetText(
      originalFile,
      setOriginalText,
      setLanguage,
      allowedLanguages,
    );
  }, [originalFile, allowedLanguages]);

  useEffectOnce(() => {
    const languages: MonacoLanguages[] = monaco.languages
      .getLanguages()
      .map(each => {
        return { name: each.id, extensions: each.extensions || [] };
      });
    setAllowedLanguages(languages);
  });

  const languageOptions: SelectItem[] = allowedLanguages
    ? allowedLanguages.map(i => ({ label: i.name, value: i.name }))
    : [{ label: 'Loading...', value: 'loading' }];

  const [newOriginalText, setNewOriginalText] = useState('');
  const [newModifiedText, setNewModifiedText] = useState('');

  const originalEditorRef = useRef<any>(null);
  const modifiedEditorRef = useRef<any>(null);

  const handleEditorMount = (editor: MonacoDiffEditor) => {
    const originalEditor = editor.getOriginalEditor();
    originalEditorRef.current = originalEditor;
    const modifiedEditor = editor.getModifiedEditor();
    modifiedEditorRef.current = modifiedEditor;
    originalEditor.onDidChangeModelContent(_ => {
      setNewOriginalText(originalEditor.getValue());
    });
    modifiedEditor.onDidChangeModelContent(_ => {
      setNewModifiedText(modifiedEditor.getValue());
    });
  };

  const handleOriginalText = (value: string) => {
    originalEditorRef.current.setValue(value);
    setOriginalText('');
  };

  const handleModifiedText = (value: string) => {
    modifiedEditorRef.current.setValue(value);
    setModifiedText('');
  };

  return (
    <FormControl className={classes.fullWidth}>
      {/* <Grid container className={classes.fullWidth}>
        <Grid item className={classes.select}>
          <Select
            selected={language}
            onChange={handleLanguageSelect}
            items={languageOptions}
            label="Select Text Language"
          />
        </Grid>
      </Grid> */}
      <Grid container className={classes.fullWidth}>
        <Grid item>
          {exampleOriginalText && exampleModifiedText && (
            <SampleButton
              setInput={input => {
                setOriginalText(input[0]);
                setModifiedText(input[1]);
              }}
              sample={[exampleOriginalText, exampleModifiedText]}
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.fileUpload}>
        <Grid item className={classes.halfWidth}>
          <ButtonGroup size="small">
            <FileUploadButton
              onFileLoad={setOriginalFile}
              id="originalFile"
              buttonText="Original File"
            />
            <ClearValueButton setValue={handleOriginalText} />
            <PasteFromClipboardButton setInput={setOriginalText} />
            {newOriginalText && (
              <CopyToClipboardButton output={newOriginalText} />
            )}
          </ButtonGroup>
        </Grid>
        <Grid item className={classes.halfWidth}>
          <ButtonGroup size="small">
            <FileUploadButton
              onFileLoad={setModifiedFile}
              id="modifiedFile"
              buttonText="Modified File"
            />
            <ClearValueButton setValue={handleModifiedText} />
            <PasteFromClipboardButton setInput={setModifiedText} />
            {newModifiedText && (
              <CopyToClipboardButton output={newModifiedText} />
            )}
          </ButtonGroup>
        </Grid>
      </Grid>
      <DiffEditor
        height="60vh"
        original={originalText}
        modified={modifiedText}
        options={options}
        language={language}
        onMount={handleEditorMount}
        className={classes.diffEditor}
      />
    </FormControl>
  );
};

export const fileDiffTool: Tool = {
  id: 'file-diff',
  name: 'File Diff',
  component: <FileDiff />,
  category: ' MISC',
  description: 'Compare Text files',
  headerButtons: [],
};

export default FileDiff;
