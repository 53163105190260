import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  Theme,
  makeStyles,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withStyles } from '@material-ui/styles';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import MessageIcon from '@material-ui/icons/Message';
import ThumbUpIcon from '../../../feedback/icons/thumbUp';
// @ts-ignore TODO: Keep track if backstage removes it
import { useApi } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import {
  IHomepageQuestionItem,
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../../apis/informationExchange';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../../apis/notificationApi';
import LeftArrowIcon from '../icons/leftArrowIcon';
import RightArrowIcon from '../icons/rightArrowIcon';
import useEmblaCarousel from 'embla-carousel-react';
import EmblaCarousel from '../../Carousal/EmblaCarousel';
import Autoplay from 'embla-carousel-autoplay';

const useStyles = makeStyles((theme) =>
  createStyles({
    promptEnhancer: {
      // background: '#032842',
      borderRadius: '8px',
      marginTop: '4px',
      color: '#fff',
      scrollBehavior: 'smooth',
      boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.14)',
      height: 'calc(100% + 4px)',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
      background: `url(/images/prompt-enhancer-image.png) right bottom no-repeat, url(/images/prompt-enhancer-background.png) left top no-repeat`,
      backgroundSize: '363px 220px, auto',
      ['@media (max-width: 768px)']: {
        background: `url(/images/prompt-enhancer-background.png) no-repeat`,
      backgroundSize: 'auto',
      }
    },
    tryNowButton: {
      backgroundColor: theme.palette.colors.white,
      color: theme.palette.colors.green,
      fontWeight: 'bolder',

    },
    promptEnhancerText: {
      fontSize: '20px'
    },
    promptEnhancerGradient: {
      // backgroundImage: `linear-gradient(180deg, transparent 60%, ${theme.palette.colors.white})`,
      width: '150px',
      padding: '2px',
      fontSize: '18px',
      // borderRadius: '2px',
      marginBottom: '75px'
    },
  })
);

const StyledBox = withStyles(
  {
    root: {
      display: 'flex',
      color: '#1A1A1A99',
      marginRight: '15px',
    },
  },
  { name: 'BackstageBreadcrumbsStyledBox' },
)(Box);

const PromptEnhancerWidget = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/prompt-enhancer');
  }
  return (
    <Box
      sx={{
        pt: 1,
        pl: 2,
        pr: 2
      }}
      className={classes.promptEnhancer}
    >
      <Grid >
        <Typography className={classes.promptEnhancerText}>
          Have you tried all new
        </Typography>
      </Grid>
      <Grid >
        <div className={classes.promptEnhancerGradient}>
        Prompt Enhancer
        </div>
      </Grid>
      <Grid>
        <Button variant="contained" className={classes.tryNowButton} onClick={onClick}>
          Try now
        </Button>
      </Grid>
    </Box>
  );
};

export default PromptEnhancerWidget;
