import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { commonStyles } from '../../../styles/common';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...commonStyles(theme),

    /*  AiSdlc Module Styles */
    '@global': {
      '#root': {
        backgroundColor:
          theme.palette.type === 'light'
            ? theme.palette.colors.sage
            : theme.palette.colors.richBlack,
      },
    },

    subHeading: {
      marginLeft: `${theme.spacing(5) + 3}px`,
    },

    /* Phase Card */
    section: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: '8px 8px 8px 8px',
      background: theme.palette.bgVariants.variant3,
    },
    phaseHeader: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      width: '100%',
      gap: '20px',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      padding: '16px',
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    headerImage: {
      aspectRatio: '1.45',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '160px',
      borderRadius: '8px',
      alignSelf: 'stretch',
      margin: 'auto 0',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    contentWrapper: {
      alignSelf: 'stretch',

      display: 'flex',
      minWidth: '240px',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      margin: 'auto 0',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
      },
    },
    textContent: {
      zIndex: 0,
      display: 'flex',
      minWidth: '240px',
      flexDirection: 'column',
      fontFamily: 'Calibre-R, sans-serif',
      color: theme.palette.text.secondary,
      justifyContent: 'center',
      margin: 'auto 0',
    },
    title: {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: 1,
    },
    description: {
      fontSize: '16px',
      fontWeight: 350,
      lineHeight: 1,
      fontFamily: 'Calibre-R-Light',
    },
    aiServicesWrapper: {
      borderRadius: '12px',
      backgroundColor: theme.palette.bgVariants.variant1,
      alignSelf: 'center',
      zIndex: 0,
      display: 'flex',
      minWidth: '240px',
      alignItems: 'center',
      gap: '16px',
      justifyContent: 'flex-start',

      padding: '12px',
    },
    aiServicesText: {
      color: theme.palette.textVariants.variant1,
      alignSelf: 'stretch',
      margin: 'auto 0',
      font: '400 14px/1 Calibre-R, -apple-system, Roboto, Helvetica, sans-serif',
    },
    aiServiceIcons: {
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      justifyContent: 'flex-start',
      margin: 'auto 0',
    },
    moreButton: {
      borderRadius: '36px',
      backgroundColor: '#fff',
      color: 'var(--Semantic-Colors-Info-Dark, #262698)',
      whiteSpace: 'nowrap',
      height: '100%',
      padding: '8px',
      font: '500 14px/1 Calibre-R, -apple-system, Roboto, Helvetica, sans-serif',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'initial',
      },
    },
    decorativeImage: {
      aspectRatio: '1',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '46px',
      alignSelf: 'flex-end',
      zIndex: 10,
      margin: '-94px 65px 0 0',
      [theme.breakpoints.down('sm')]: {
        marginRight: '10px',
      },
    },
    footerImage: {
      aspectRatio: '3.14',
      objectFit: 'contain',
      objectPosition: 'center',
      width: '100%',
      marginTop: '48px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        marginTop: '40px',
      },
    },

    phaseBody: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      gap: '20px',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      padding: '0px 16px 0px 16px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      transition: 'height 1s ease-out',
    },

    expanded: {
      paddingBottom: '16px',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
    },
    iconWrapper: {
      borderRadius: '40px',
      backgroundColor: '#fff',
      alignSelf: 'stretch',
      display: 'flex',
      minHeight: '40px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      margin: 'auto 0',
      padding: '0 8px',

      '&.Mui-disabled': {
        backgroundColor: '#fff',
      },
    },

    phaseToggleButton: {
      padding: `${theme.spacing(0.5)}px`,
      minWidth: 'auto',
      backgroundColor: theme.palette.bgVariants.variant2,
      width: theme.spacing(5),
      height: theme.spacing(5),
      marginLeft: theme.spacing(6),
      boxShadow: 'none',
    },

    /* End of Phase Card */

    /* End of AiSdlc Module stylings */
  }),
);
