/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useStyles } from '../../../../styles';
import {
  IApmDetails,
  IGlobalTraffic,
  IOptionsGlobalTraffic,
} from '../../../../interfaces';
import { v4 as uuidv4 } from 'uuid';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';

const LoadDistribution = ({
  isVisible,
  apmInfo,
  onPrevious,
  onNext,
  options,
  initialState,
}: {
  isVisible: boolean;
  apmInfo: IApmDetails | null;
  onPrevious: Function;
  onNext: Function;
  options: IOptionsGlobalTraffic;
  initialState: IGlobalTraffic[] | undefined;
}) => {
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState<any>(false);

  const rangeValidation = (
    value: number,
    minRange: number,
    maxRange: number,
  ) => {
    if (minRange && maxRange) return value >= minRange && value <= maxRange;
    if (minRange) return value >= minRange;
    return false;
  };

  const rangeValidationKeys = [
    'threadCount',
    'rampUpSeconds',
    'durationSeconds',
  ];

  const emptyLoadDistribution = {
    main: {
      region: '',
      threadCount: '',
      rampUpSeconds: '',
      durationSeconds: '',
    },
    errors: {
      region: '',
      threadCount: '',
      rampUpSeconds: '',
      durationSeconds: '',
    },
  };
  const [loadDistributions, setLoadDistributions] = useState<any>([
    { id: uuidv4(), ...emptyLoadDistribution },
  ]);

  const handleAddDistribution = () => {
    setLoadDistributions((prevState: any) => [
      ...prevState,
      { id: uuidv4(), ...emptyLoadDistribution },
    ]);
  };

  const handleDeleteDistribution = (id: string) => {
    setLoadDistributions(
      loadDistributions.filter(
        (loadDistribution: any) => loadDistribution?.id !== id,
      ),
    );
  };

  const handleDitributionChange = (event: any, id: string) => {
    const { name, value } = event.target;
    if (showBanner) {
      setShowBanner(false);
    }
    setLoadDistributions((prevState: any) =>
      prevState.map((loadDistribution: any) => {
        if (!showBanner && loadDistribution.main.region === 'EMEA') {
          setShowBanner((prevState: any) => {
            if (!prevState) return true;
            return
          });
        }
        return loadDistribution.id === id
          ? {
              ...loadDistribution,
              main: { ...loadDistribution.main, [name]: value },
              errors: { ...loadDistribution.errors, [name]: '' },
            }
          : loadDistribution;
      }),
    );
  };

  const updateErrors = (errors: any, main: any) => {
    const errorObj: any = {};
    Object.keys(errors).forEach(key => {
      const ObjKey = key as keyof Object;
      //   errorObj[ObjKey] = main[ObjKey] ? '' : 'field is required';

      if (main[ObjKey]) {
        if (rangeValidationKeys.includes(ObjKey)) {
          if (
            !rangeValidation(
              parseInt(main[ObjKey]),
              options[ObjKey].min,
              options[ObjKey].max,
            )
          ) {
            errorObj[ObjKey] =
              options[ObjKey].min && options[ObjKey].max
                ? `range should be >= ${options[ObjKey].min} and <=  ${options[ObjKey].max}`
                : options[ObjKey].min
                ? `range should be >= ${options[ObjKey].min}`
                : '';
          } else {
            errorObj[ObjKey] = '';
          }
        } else {
          errorObj[ObjKey] = '';
        }
      } else {
        errorObj[ObjKey] = `${ObjKey} is required`;
      }
    });

    return {
      errorObj,
      hasError: Object.values(errorObj).some((x: any) => x !== ''),
    };
  };

  const validate = () => {
    let errorsArr: boolean[] = [];
    const arr = loadDistributions.map((loadDistribution: any) => {
      const { main, errors } = loadDistribution;
      const { errorObj, hasError } = updateErrors(errors, main);
      errorsArr = [...errorsArr, hasError];
      return { ...loadDistribution, errors: errorObj };
    });

    setLoadDistributions(arr);
    return errorsArr.every((x: any) => x === false);
  };

  const handleNext = () => {
    if (validate())
      onNext({
        globalTraffic: loadDistributions?.map(
          (loadDistribution: any) => loadDistribution.main,
        ),
      });
  };

  useEffect(() => {
    if (initialState) {
      setLoadDistributions([]);
      initialState?.map((globalTraffic: IGlobalTraffic) => {
        setLoadDistributions((prevState: IGlobalTraffic[]) => [
          ...prevState,
          {
            id: uuidv4(),
            main: globalTraffic,
            errors: emptyLoadDistribution.errors,
          },
        ]);
      });
    }
  }, [initialState]);

  return (
    <Card
      className={`${classes.heigt100Percent} ${
        isVisible ? '' : classes.displayNone
      } `}
    >
      <CardHeader
        title="Global Traffic"
        action={
          <IconButton
            className={classes.padding1}
            onClick={() => handleAddDistribution()}
            disabled
          >
            <AddIcon fontSize="medium" />
          </IconButton>
        }
      />
      <CardContent className={`${classes.overflowAuto}`}>
        {loadDistributions.map((loadDistribution: any) => {
          return (
            <Grid
              container
              spacing={4}
              key={loadDistribution.id}
              className={classes.flexNoWrap}
            >
              <Grid
                item
                xs="auto"
                sm="auto"
                md={11}
                lg={11}
                //   className={classes.flexGrow1}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      required
                      {...(loadDistribution.errors.region && {
                        error: true,
                        helperText: loadDistribution.errors.region,
                      })}
                    >
                      <InputLabel htmlFor="outlined-FlowId-native-simple">
                        Region
                      </InputLabel>
                      <Select
                        value={loadDistribution.main.region}
                        onChange={(event: any) => {
                          handleDitributionChange(event, loadDistribution.id);
                        }}
                        label="Region"
                        name="region"
                      >
                        {options?.region.map((region: any) => (
                          <MenuItem value={region} key={region}>
                            {region}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {loadDistribution.errors.region}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <TextField
                      required
                      label="Thread count"
                      variant="outlined"
                      name="threadCount"
                      value={loadDistribution.main.threadCount}
                      onChange={(event: any) => {
                        handleDitributionChange(event, loadDistribution.id);
                      }}
                      {...(loadDistribution.errors.threadCount && {
                        error: true,
                        helperText: loadDistribution.errors.threadCount,
                      })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <TextField
                      required
                      label="Ramp up"
                      variant="outlined"
                      name="rampUpSeconds"
                      value={loadDistribution.main.rampUpSeconds}
                      onChange={(event: any) => {
                        handleDitributionChange(event, loadDistribution.id);
                      }}
                      {...(loadDistribution.errors.rampUpSeconds && {
                        error: true,
                        helperText: loadDistribution.errors.rampUpSeconds,
                      })}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            Seconds
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={3}>
                    <TextField
                      required
                      label="Duration"
                      variant="outlined"
                      name="durationSeconds"
                      value={loadDistribution.main.durationSeconds}
                      onChange={(event: any) => {
                        handleDitributionChange(event, loadDistribution.id);
                      }}
                      {...(loadDistribution.errors.durationSeconds && {
                        error: true,
                        helperText: loadDistribution.errors.durationSeconds,
                      })}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            Seconds
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs="auto"
                sm="auto"
                md={1}
                lg={1}
                className={`${classes.flexCenter}`}
              >
                <IconButton
                  className={classes.padding1}
                  onClick={() => handleDeleteDistribution(loadDistribution.id)}
                  disabled={loadDistributions.length === 1}
                >
                  <DeleteOutlineOutlined fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </CardContent>
      <CardActions className={`${classes.padding2} ${classes.addConfigFooter}`}>
        <Button variant="outlined" onClick={() => onPrevious()}>
          Previous
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </CardActions>
    </Card>
  );
};

export default LoadDistribution;
