/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Content, PageWithHeader, useSidebarPinState } from '@backstage/core-components';
import React, { useEffect, useState, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
// @ts-ignore
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// @ts-ignore
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import rehypeExternalLinks from 'rehype-external-links';
import remarkFrontmatter from 'remark-frontmatter';
// @ts-ignore
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { Button, IconButton, Grid, Box, Paper, Typography, TextField, LinearProgress } from '@material-ui/core';
import { useStyles } from './styles';
import BackButton from '../utils/backButton';
import { HelpComponent } from '../utils/helpComponent';
import HELP_URL from '../utils/helpLinkConstant';
import { useBuildPrompt } from '../PromptStore/hooks/useBuildPrompt';
import {
  NotificationApi,
  notificationApiRef,
} from '../../apis/notificationApi';
import { AuthContext } from '../../providers/AuthProvider';
import { useApi } from '@backstage/core-plugin-api';
import { userApiRef } from '../../apis/userApi';
import MagicWandIcon from './icons/MagicWandIcon';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
interface PromptEnhancerPageProps {
  // Add any props you need here
}

const PromptEnhancerPage: React.FC<PromptEnhancerPageProps> = () => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const classes = useStyles();
  const { profEmail } = useContext(AuthContext);
  const { isPinned } = useSidebarPinState();

  const header = {
    title: 'Prompt Store',
    subTitleRoutes: [
      {
        type: 'link',
        text: 'Home',
        link: '/',
      },
      {
        type: 'link',
        text: 'Prompt Store',
        link: '/prompt-store',
      },
      {
        type: 'text',
        text: 'Prompt Enhancer',
      },
    ],
  };

  const [inputPrompt, setInputPrompt] = useState('');
  const [imageData, setImageData] = useState<any>('');
  const [inputWidth, setInputWidth] = useState<string>('');
  const [responseGenerated, setResponseGenerated] = useState(false);
  const [generatedResponse, setGeneratedResponse] = useState<string[]>([]);
  const userApi = useApi(userApiRef);

  useEffect(() => {
    if (isPinned) {
      setInputWidth('calc(100% - 338px)');
      console.log('im here')
    } else {
      setInputWidth('calc(100% - 186px)');
      console.log('im here 2')
    }
  }, [isPinned])

  const {
    generatePrompt,
    generatePromptV2,
    enhancedPrompt,
    setEnhancedPrompt,
    regenerationToken,
    setRegenerationToken,
    promptInprogress,
    promptLoaded,
    setPromptLoaded,
  } = useBuildPrompt();

  const handleGenerateClick = () => {
    setResponseGenerated(true);
    setGeneratedResponse([]);
    generatePrompt({
      prompt: inputPrompt
    });
  };

  const handleReGenerateClick = () => {
    setGeneratedResponse([...generatedResponse, enhancedPrompt]);
    setEnhancedPrompt('');
    setPromptLoaded(false);
    generatePrompt({
      prompt: inputPrompt,
      regenerate: true,
      regeneration_token: regenerationToken,
    });
  };

  const handleCopyToClipboard = (_: any, idx?: number) => {
    if (typeof idx !== 'number') {
      navigator.clipboard.writeText(enhancedPrompt);
    } else {
      navigator.clipboard.writeText(generatedResponse[idx]);
    }
    notificationApi.sendNotification({
      message: 'Copied to clipboard!',
      disapperAfterMs: 2500,
      severity: 'success',
    });
  };

  const handleClear = () => {
    setResponseGenerated(false);
    setGeneratedResponse([]);
    setInputPrompt('');
    setEnhancedPrompt('');
    setPromptLoaded(false);
    setRegenerationToken('');
  };

  const WelcomeWidget = () => (<Grid item className={`${classes.flexCenter} ${classes.welcomeWidget}`} >
    <Box
      maxWidth='700px'
      className={classes.welcomeContent}
    >
      <Typography variant="h6" gutterBottom>
        <MagicWandIcon variant="outlined" />{' '} Hey there, {' ' + profEmail.split('.')[0]}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Did you know - A prompt enhancer helps you craft clearer instructions for AI tasks, boosting the accuracy and quality of the results.
      </Typography>
      <Typography variant="body2" gutterBottom>
        The enhancer is targeted for D&T community and will help with Software related inputs. The output will add context and templatize the prompt so that you can re-use it again as needed and share it with others. It will also call out the intent and the keywords that can be converted to placeholders.
      </Typography>
      <ol>
        <li>
          Give Direction - Describe the desired style in detail, or reference a relevant persona.
        </li>
        <li>
          Specify Format - Define what rules to follow, and the required structure of the response.
        </li>
        <li>
          Provide Examples - Insert a diverse set of test cases where the task was done correctly.
        </li>
        <li>
          Evaluate Quality - Identify errors and rate responses, testing what drives performance.
        </li>
        <li>
          Divide Labor - Split tasks into multiple steps, chained together for complex goals.
        </li>
      </ol>
    </Box>
  </Grid>);
  useEffect(() => {
    if (userApi) {
      userApi
        .getImage(profEmail)
        .then((data: any) => {
          if (typeof data == 'object') {
            setImageData(undefined);
          } else {
            setImageData(data);
          }
        })
        .catch((err: any) => setImageData(undefined));
    }
    return () => {
      setImageData(undefined);
    };
  }, [profEmail]);

  const ChatWidget = () => {
    return <div className={classes.chatContent}>
      <Grid className={`${classes.flex} ${classes.chatResponse}`}>
        <Avatar
          picture={imageData}
          displayName={profEmail}
          customStyles={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            fontSize: '10px',
            marginLeft: '5px',
            marginRight: '8px',
            padding: '0px',
          }}

        />
        <ReactMarkdown
          children={inputPrompt}
          linkTarget='_blank'
          remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
          rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
          className={`${classes.chat} react-markdown-ai-response`}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');

              return (
                <>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={atomDark}
                    language={match ? match[1] : 'jsx'}
                    PreTag="div"
                    {...props}
                  />
                </>
              );
            },
          }}
        />
      </Grid>

      {
        generatedResponse.length > 0 && generatedResponse.map((response, idx) => (
          <>
            <Grid className={`${classes.flex} ${classes.chatResponse}`} key={idx}>
              <div className={classes.chatAvatar}>
                <MagicWandIcon variant="filled" />
              </div>
              <ReactMarkdown
                children={response}
                linkTarget='_blank'
                remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
                rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
                className={`${classes.chat} react-markdown-ai-response`}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '');
                    return (
                      <>
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={atomDark}
                          language={match ? match[1] : 'jsx'}
                          PreTag="div"
                          {...props}
                        />
                      </>
                    );
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '16px' }}>
              <IconButton onClick={(_) => handleCopyToClipboard(_, idx)}>
                <FileCopyIcon />
              </IconButton>
            </Grid>
          </>
        ))
      }
      {
        promptInprogress && !enhancedPrompt && <LinearProgress />
      }
      <Grid className={`${classes.flex} ${classes.chatResponse}`}>
        <div className={classes.chatAvatar}>
          <MagicWandIcon variant="filled" />
        </div>
        <ReactMarkdown
          children={enhancedPrompt}
          linkTarget='_blank'
          remarkPlugins={[remarkGfm, remarkSlug, remarkFrontmatter]}
          rehypePlugins={[rehypeRaw, rehypeExternalLinks]}
          className={`${classes.chat} react-markdown-ai-response`}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return (
                <>
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={atomDark}
                    language={match ? match[1] : 'jsx'}
                    PreTag="div"
                    {...props}
                  />
                </>
              );
            },
          }}
        />
      </Grid>
      {
        promptLoaded && <Grid item xs={12} style={{ marginLeft: '16px' }}>
          <IconButton onClick={handleCopyToClipboard}>
            <FileCopyIcon />
          </IconButton>
          <IconButton onClick={handleReGenerateClick}>
            <RefreshIcon />
          </IconButton>
        </Grid>
      }
    </div>
  }

  return (
    <PageWithHeader
      title={header.title}
      themeId="promptStore"
      pageTitleOverride="Prompt Enhancer"
      subtitle={<DevxBreadCrumb routes={header.subTitleRoutes} />}
    >
      <Content className={classes.content}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.flexSpacebetween}
          >
            <BackButton />
            <div className={classes.buttonSection}>
              <Button variant="contained" color="primary" onClick={handleClear}>
                Refresh Session
              </Button>
              <HelpComponent helpUrl={HELP_URL.PROMPT_STORE} />
            </div>
          </Grid>
        </Grid>
        {
          (responseGenerated) ? <ChatWidget /> : <WelcomeWidget />
        }

        <Paper className={`${classes.inputField}`} style={{
          width: inputWidth,
        }}>
          <Grid className={`${classes.flex}`}>
            <TextField
              fullWidth
              placeholder={responseGenerated ? '': 'Enter your prompt here...'}
              multiline
              minRows={3}
              maxRows={30}
              InputProps={{
                disableUnderline: true,
              }}
              onChange={(event: any) => {
                setInputPrompt(event?.target?.value);
              }}
              onKeyDown={(event: any) => {
                if (event.key==='Enter' && event.shiftKey) {
                  handleGenerateClick();
                }
              }}
              value={responseGenerated ? '' : inputPrompt}
              disabled={promptInprogress}
            />
            <IconButton onClick={handleGenerateClick} disabled={promptInprogress}>
              <MagicWandIcon variant="filled" />
            </IconButton>
          </Grid>
        </Paper>
      </Content>
    </PageWithHeader>
  );
};

export default PromptEnhancerPage;